import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { useTranslation } from '../../i18n';
import { IMainState } from '../../interfaces';
import { DID_SHARE } from '../../utils/constants';
import { PageView } from '../../utils/pageView';
import {
  getShortenedUrlWithLang,
  ShareScenario,
  ShortenUrlQuery
} from '../../utils/shareUrl';
import { isInChina } from '../../utils/stores';
import { performNativeShare } from '../../utils/window';
import { AppContext, IAppContext } from '../AppContext';
import ToolTipWrapper from '../Common/ToolTipWrapper';
import ShareButton from './ShareButton';
import ShareModal from './ShareModal';
import SimpleShareModal from './SimpleShareModal';

interface ModalProps {
  hasBackButton: boolean;
  shareText: string;
  shareImage?: string;
  open: boolean;
  onClose: () => void;
  popupType?: 'default' | 'simple';
}

export interface ShareProps extends ModalProps {
  onOpen: () => void;
  type: PageView;
  id: string;
  urlOnly?: boolean;
  externalUrl?: string;
  additionalLogInfo?: Record<string, any>;
}

interface ShareContext {
  scenario: ShareScenario;
  context: IAppContext | undefined;
  query?: ShortenUrlQuery;
}
const Share: React.FC<ShareProps> = ({
  hasBackButton,
  shareText,
  shareImage,
  onClose,
  open,
  onOpen,
  type,
  id,
  popupType,
  urlOnly,
  externalUrl,
  additionalLogInfo = {}
}) => {
  const [shareUrl, setShareUrl] = useState<string>(null);
  const { i18n } = useTranslation();
  const router = useRouter();
  const isSharable = router.query.sharable === 'true';

  const context = React.useContext(AppContext);

  const [loading, setLoading] = React.useState(false);

  const handleShare = (channel = '') => {
    logEvent(DID_SHARE, channel, {
      channel,
      type,
      id,
      storeId: context.store?.id,
      ...(additionalLogInfo || {})
    });
  };

  const clientState = useSelector((state: IMainState) => state.clientState);
  const productsToCompare = clientState?.productsToCompare;
  const productAccessories = clientState?.productAccessories;

  const shareContext = React.useMemo<ShareContext>(() => {
    switch (type) {
      case PageView.PRODUCT_COMPARE:
        return {
          scenario: ShareScenario.COMPARE,
          context,
          query: {
            products: productsToCompare
          }
        };
      case PageView.PRODUCT:
        return {
          scenario: ShareScenario.PRODUCT,
          context,
          query: {
            productId: id
          }
        };
      case PageView.PRODUCT_PERSONALIZE:
        return {
          scenario: ShareScenario.PERSONALIZE,
          context,
          query: {
            personalize: {
              strap: productAccessories?.selectedStrap?.id,
              buckle: productAccessories?.selectedBuckle?.id
            }
          }
        };
      case PageView.STORYBOOK:
        return {
          scenario: ShareScenario.STORYBOOK,
          context
        };
      default:
        return {
          scenario: ShareScenario.GENERAL,
          context
        };
    }
  }, [type, productsToCompare, productAccessories, id]);

  if (isInChina(context.store?.id)) {
    return null;
  }

  if (isSharable) {
    return null;
  }

  const onShare = () => {
    const { context, scenario, query } = shareContext;
    setLoading(true);
    getShortenedUrlWithLang(
      scenario,
      context,
      query,
      i18n.language,
      externalUrl
    )
      .then((url) => {
        setShareUrl(url);
        performNativeShare(
          {
            title: urlOnly ? '' : context.storeConfig?.brandName,
            text: urlOnly ? '' : shareText,
            url
          },
          () => handleShare('native'),
          onOpen
        );
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const simpleShareModal = open && (
    <ToolTipWrapper
      onClose={onClose}
      parentId="shareButton"
      width={250}
      xPosition="right"
    >
      <div className="control-menu text-left">
        <SimpleShareModal
          show={true}
          url={shareUrl}
          onShare={handleShare}
          title={shareText}
          type="menu"
          qrUrl={shareUrl}
          darkMode={true}
          urlOnly={urlOnly}
        />
      </div>
    </ToolTipWrapper>
  );

  return (
    <div>
      {
        <ShareButton
          hasBackButton={hasBackButton}
          onShare={onShare}
          loading={loading}
        />
      }
      {popupType === 'simple' ? (
        simpleShareModal
      ) : (
        <ShareModal
          shareUrl={shareUrl}
          shareText={shareText}
          shareImage={shareImage}
          open={open}
          onClose={onClose}
          onShare={handleShare}
        />
      )}
    </div>
  );
};

export default Share;
