import { ILanguageOption } from '../interfaces';
import { I18n } from 'next-i18next';

export const setDefaultLanguage =
  (i18n: I18n) =>
  (queryLanguage?: string, languageOptions?: ILanguageOption[]) => {
    if (queryLanguage) {
      i18n.changeLanguage(queryLanguage);
      return;
    }
    const defaultLanguage = languageOptions?.find((l) => l.default);
    if (defaultLanguage) {
      i18n.changeLanguage(defaultLanguage.code);
      return;
    }
    const knownLanguages = languageOptions?.map((option) => option?.code) || [];
    if (knownLanguages.length === 0) {
      i18n.changeLanguage('en');
      return;
    }
    if (knownLanguages.length === 1) {
      i18n.changeLanguage(knownLanguages[0]);
      return;
    }
    if (!knownLanguages.some((l) => l === i18n.language)) {
      i18n.changeLanguage(knownLanguages[0]);
      return;
    }
  };
export const currentLanguage = (i18n: I18n) => i18n?.language || 'en';
export const drawI18nt = (i18n: I18n, str: string) => i18n?.t(str) || str;
