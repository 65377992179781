import React from 'react';
import { Modal } from 'react-bootstrap';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';
import { iwcUaeDubaiBoutiqueId } from '../../config';
import { useTranslation } from '../../i18n';
import { AppContext } from '../AppContext';

interface ModalProps {
  shareUrl: string;
  shareText: string;
  shareImage?: string;
  open: boolean;
  onClose: () => void;
  onShare?: any;
}

const ShareModal = ({
  shareUrl,
  shareText,
  open,
  onClose,
  shareImage,
  onShare
}: ModalProps) => {
  const { t } = useTranslation();
  const { store } = React.useContext(AppContext);

  const isIWCDubai = store?.id === iwcUaeDubaiBoutiqueId;

  return (
    <Modal show={open} onHide={onClose} className="sharemodal">
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle">{t('share_this_page')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <WhatsappShareButton
          url={shareUrl}
          title={shareText}
          separator=" "
          className="btnShare"
          beforeOnClick={() => onShare('whatsapp')}
          windowWidth={920}
          windowHeight={720}
        >
          <WhatsappIcon
            bgStyle={{ fill: '#343740' }}
            size={32}
            round
            crossOrigin="anonymous"
          />
          <span className="btnShareSpan">{t('whatsapp')}</span>
        </WhatsappShareButton>

        {isIWCDubai ? null : (
          <>
            <LineShareButton
              url={shareUrl}
              title={shareText}
              className="btnShare"
              beforeOnClick={() => onShare('line')}
            >
              <LineIcon
                bgStyle={{ fill: '#343740' }}
                size={32}
                round
                crossOrigin="anonymous"
              />
              <span className="btnShareSpan">{t('line')}</span>
            </LineShareButton>

            <TelegramShareButton
              url={shareUrl}
              title={shareText}
              className="btnShare"
              beforeOnClick={() => onShare('telegram')}
            >
              <TelegramIcon
                bgStyle={{ fill: '#343740' }}
                size={32}
                round
                crossOrigin="anonymous"
              />
              <span className="btnShareSpan">{t('telegram')}</span>
            </TelegramShareButton>
          </>
        )}

        <FacebookShareButton
          url={shareUrl}
          quote={shareText}
          className="btnShare"
          beforeOnClick={() => onShare('facebook')}
        >
          <FacebookIcon
            bgStyle={{ fill: '#343740' }}
            size={32}
            round
            crossOrigin="anonymous"
          />
          <span className="btnShareSpan">{t('facebook')}</span>
        </FacebookShareButton>

        <TwitterShareButton
          url={shareUrl}
          title={shareText}
          className="btnShare"
          beforeOnClick={() => onShare('twitter')}
        >
          <TwitterIcon
            bgStyle={{ fill: '#343740' }}
            size={32}
            round
            crossOrigin="anonymous"
          />
          <span className="btnShareSpan">{t('twitter')}</span>
        </TwitterShareButton>

        <LinkedinShareButton
          url={shareUrl}
          className="btnShare"
          beforeOnClick={() => onShare('linkedin')}
        >
          <LinkedinIcon
            bgStyle={{ fill: '#343740' }}
            size={32}
            round
            crossOrigin="anonymous"
          />
          <span className="btnShareSpan">{t('linkedin')}</span>
        </LinkedinShareButton>

        <PinterestShareButton
          url={shareUrl}
          media={shareImage}
          className="btnShare"
          beforeOnClick={() => onShare('pinterest')}
        >
          <PinterestIcon
            bgStyle={{ fill: '#343740' }}
            size={32}
            round
            crossOrigin="anonymous"
          />
          <span className="btnShareSpan">{t('pinterest')}</span>
        </PinterestShareButton>

        <EmailShareButton
          url={shareUrl}
          subject={shareText}
          body={shareText}
          separator=" "
          className="btnShare"
          beforeOnClick={() => onShare('email')}
        >
          <EmailIcon
            bgStyle={{ fill: '#343740' }}
            size={32}
            round
            crossOrigin="anonymous"
          />
          <span className="btnShareSpan">{t('email')}</span>
        </EmailShareButton>
      </Modal.Body>
      <style jsx global>
        {`
          .sharemodal .btnShare {
            width: 50%;
            text-align: left;
            border: 1px solid #efefef !important;
            border-width: 0 0 1px 1px !important;
            padding: 20px 16px !important;
            outline: none;
          }
          .sharemodal .btnShare:hover {
            background-color: #f1f1f1 !important;
          }
          .sharemodal .btnShareSpan {
            padding-left: 8px;
          }

          .sharemodal .modalTitle {
            font-size: 1.1em;
            letter-spacing: 0;
          }

          .sharemodal .modalBody {
            display: flex;
            flex-wrap: wrap;
            padding: 0 !important;
          }
        `}
      </style>
    </Modal>
  );
};

export default ShareModal;
