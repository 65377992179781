import React from 'react';
import { isUserOniOS } from '../utils/deviceDetector';

interface ILayoutProps {
  children: React.ReactNode;
}

const PreventMobileZoomContainer: React.FC<ILayoutProps> = (
  props: ILayoutProps
) => {
  React.useEffect(() => {
    if (isUserOniOS()) {
      const touchMoveHandler = function (event) {
        if (event['scale'] !== 1) {
          event.preventDefault();
        }
      };
      document.addEventListener('touchmove', touchMoveHandler, {
        passive: false
      });
      return () => {
        document.removeEventListener('touchmove', touchMoveHandler);
      };
    }
  }, []);

  return <>{props.children}</>;
};

export default PreventMobileZoomContainer;
