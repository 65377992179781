import Head from 'next/head';
import {
  siteName,
  iwcBrandId,
  alsBrandId,
  piagetBrandId,
  getBrandNameById,
  jlcBrandId,
  rduBrandId,
  louboutinBrandId,
  zegnaBrandId,
  chopardBrandId,
  vacBrandId,
  cartierBrandId,
  vanCleefArpelsBrandId,
  aspreyBrandId,
  tiffanyBrandId,
  gustoLuxeBrandId,
  chopardHappySportKorea,
  chopardHappySportGenevaHQ
} from '../config';
import React, { ReactNode } from 'react';
import { isEmbeddedInStreamingStudio } from '../utils/streamingstudio';
import { replaceAssetLink } from '../advisorHub/utils/common';

export interface HeaderProps {
  title?: string;
  description?: string;
  image?: string;
  brandId?: string;
  noCrawler?: boolean;
  children?: ReactNode;
  storeId?: string;
  primaryColor?: string;
}

const getFavicon = (brandId: string | null | undefined): string => {
  switch (brandId) {
    case iwcBrandId:
      return '/asset/iwc/favicon.ico';
    case alsBrandId:
      return '/asset/als/favicon.ico';
    case piagetBrandId:
      return '/asset/piaget/favicon.ico';
    case jlcBrandId:
      return '/asset/jlc/favicon.ico';
    case rduBrandId:
      return '/asset/rdu/favicon.ico';
    case louboutinBrandId:
      return '/asset/louboutin/favicon.ico';
    case zegnaBrandId:
      return '/asset/zegna/favicon.ico';
    case chopardBrandId:
      return '/asset/chopard/favicon.ico';
    case vacBrandId:
      return '/asset/vac/favicon.ico';
    case cartierBrandId:
      return '/asset/cartier/favicon.ico';
    case vanCleefArpelsBrandId:
      return '/asset/vancleef/favicon.png';
    case aspreyBrandId:
      return '/asset/asprey/favicon.png';
    case tiffanyBrandId:
      return '/asset/tiffany/favicon.ico';
    case gustoLuxeBrandId:
      return '/asset/gustoluxe/favicon.png';
    default:
      return '/favicon.png';
  }
};

const HTMLHeader = ({
  title,
  description,
  image,
  brandId,
  noCrawler,
  children,
  storeId,
  primaryColor = ''
}: HeaderProps) => {
  const [isEmbeddedInSsp, setIsEmbeddedInSsp] = React.useState(false);
  React.useEffect(() => {
    setIsEmbeddedInSsp(isEmbeddedInStreamingStudio());
  }, []);
  return (
    <Head>
      <title>{title}</title>
      {noCrawler && <meta name="robots" content="noindex" />}
      <meta name="description" content={description} />
      <meta name="image" content={replaceAssetLink(image)}></meta>
      <meta property="og:title" content={title}></meta>
      <meta property="og:description" content={description}></meta>
      <meta property="og:image" content={replaceAssetLink(image)}></meta>
      <meta
        property="og:site_name"
        content={getBrandNameById(brandId) || siteName}
      ></meta>
      <meta
        name="viewport"
        content={
          isEmbeddedInSsp
            ? 'width=device-width'
            : `width=device-width,initial-scale=1${
                storeId === chopardHappySportKorea || storeId === chopardHappySportGenevaHQ ? ',user-scalable=no' : ''
              }`
        }
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta
        property="twitter:site"
        content={getBrandNameById(brandId) || siteName}
      ></meta>
      <link rel="shortcut icon" href={getFavicon(brandId)} />
      <link rel="apple-touch-icon" href={getFavicon(brandId)} />
      {primaryColor && <meta name="theme-color" content={primaryColor} />}
      {children}
    </Head>
  );
};

export default HTMLHeader;
