import React from 'react';
import { Spinner } from 'react-bootstrap';

const ShareButton = ({
  hasBackButton,
  onShare,
  loading = false
}: {
  hasBackButton: boolean;
  onShare: () => void;
  loading: boolean;
}) => {
  return (
    <button className="btn share" id="shareButton" onClick={() => onShare()}>
      {!loading && (
        <svg
          height="28px"
          width="28px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          x="0px"
          y="0px"
        >
          <g data-name="Layer 2">
            <path d="M18,10a4,4,0,1,0-3.91-3.16L9,9.38a4,4,0,1,0,0,5.24l5.09,2.55A4.06,4.06,0,1,0,15,15.38L9.91,12.84a3.89,3.89,0,0,0,0-1.67L15,8.62A4,4,0,0,0,18,10Zm0,6a2,2,0,1,1-2,2A2,2,0,0,1,18,16ZM6,14a2,2,0,1,1,2-2A2,2,0,0,1,6,14ZM18,4a2,2,0,1,1-2,2A2,2,0,0,1,18,4Z"></path>
          </g>
        </svg>
      )}
      {loading && <Spinner animation="border" size="sm" />}
      <style jsx>
        {`
          .btn {
            padding: 0;
            z-index: 101;
            display: flex;
            align-items: center;
            color: #fff;
            left: ${hasBackButton ? '64px;' : '10px;'};
            top: 8px;
            position: absolute;
            width: 50px;
          }
          .btn,
          .btn:focus,
          .btn:active {
            outline: none;
            border: none;
            box-shadow: none;
          }
          svg {
            fill: #fff;
          }
          @media (min-width: 768px) {
            .share {
              top: 10px;
            }
          }

          @media (max-width: 768px) {
            .btn {
              left: ${hasBackButton ? '60px;' : '10px;'};
            }
          }
        `}
      </style>
    </button>
  );
};

export default ShareButton;
