import { compareAsc } from 'date-fns';
import _ from 'lodash';
import { ISessionDetails } from '../../interfaces';
import { getSessionStartEndTime } from '../../utils/session';

export function paginate(items: any[], pageNumber: number, pageSize: number) {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
}

export const sortSessionsChronologically = (sessions: ISessionDetails[]) => {
  return sessions.sort((sessionOne, sessionTwo) => {
    const [sessionOneStartDate] = getSessionStartEndTime(sessionOne);
    const [sessionTwoStartDate] = getSessionStartEndTime(sessionTwo);
    return compareAsc(sessionOneStartDate, sessionTwoStartDate);
  });
};

export const replaceAssetLink = (s3Link) => {
  return s3Link
    ? s3Link.replace(
        'https://inspify-assets.s3.ap-southeast-1.amazonaws.com',
        'https://assets.inspify.com'
      )
    : s3Link;
};