import Router, { useRouter } from 'next/router';
import React from 'react';
import Share from './Sharing/Share';
import { IMainState } from '../interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { actionDidBackButton } from '../redux/actions';
import { PageView, getPath } from '../utils/pageView';
import { FaPlay } from 'react-icons/fa';
import { isEmbeddedInStreamingStudio } from '../utils/streamingstudio';
import { INSPIFY_LIGHT_LOGO } from '../config';

export interface ShareHeaderProps {
  title: string;
  shareTitle: string;
  type: PageView;
  id: string;
  shareImage?: string;
  showShare?: boolean;
}

const HeaderDefault = ({
  title,
  shareTitle,
  shareImage,
  type,
  id,
  showShare = true
}: ShareHeaderProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [SspEmbed, setSspEmbed] = React.useState(true);
  const pathHistory =
    useSelector((state: IMainState) => state.clientState.pathHistory) || [];
  const router = useRouter();
  const isFromStorybook = !!router.query?.storybook;

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    setSspEmbed(isEmbeddedInStreamingStudio());
  }, []);

  return (
    <header
      className={`text-center hide-for-client-meeting ${
        isFromStorybook ? 'popup-storybook' : ''
      }`}
    >
      {pathHistory.length > 1 && (
        <span
          className="btn prev"
          onClick={() => {
            dispatch(actionDidBackButton(type));
            const path = pathHistory[pathHistory.length - 1];

            if (!path?.pageView) {
              return;
            }
            const query = path?.url?.split('?')?.[1];
            const attributes = `${query ? '?' + query : ''}`;
            if (path.pageView === PageView.WISHLIST) {
              Router.push(`/wishlist${attributes}`);
            } else if (path.pageView === PageView.PICK_OF_THE_MONTH) {
              Router.push(
                `/${getPath(path.pageView)}/[brand]/[month]${attributes}`,
                path.url
              );
            } else {
              Router.push(
                `/${getPath(path.pageView)}/[id]${attributes}`,
                path.url
              );
            }
          }}
        >
          <FaPlay />
        </span>
      )}
      {title ? (
        <span className="pagetitle">{title}</span>
      ) : (
        <img src={INSPIFY_LIGHT_LOGO} alt="Inspify" className="logo" />
      )}

      {showShare && !SspEmbed && (
        <Share
          hasBackButton={pathHistory.length > 1}
          shareText={shareTitle}
          shareImage={shareImage}
          open={open}
          onOpen={handleOpenModal}
          onClose={handleCloseModal}
          type={type}
          id={id}
        />
      )}
      <style jsx>
        {`
          .pagetitle {
            color: #fff;
            letter-spacing: 1px;
            margin: auto;
          }
          @media (min-width: 768px) {
            .pagetitle {
              margin: auto;
            }
          }
          .logo {
            width: 80px;
            margin: auto;
          }

          @media (min-width: 768px) {
            .logo {
              width: 100px;
            }
          }

          @media (max-width: 768px) {
            header {
              background: #ffffff;
              border-bottom: 1px solid #ddd;
            }

            .pagetitle {
              display: none;
            }

            header :global(svg) {
              fill: #000;
            }
          }

          .popup-storybook {
            background: #ffffff;
            border-bottom: 1px solid #ddd;
          }

          .popup-storybook .pagetitle {
            display: none;
          }

          .popup-storybook :global(svg) {
            fill: #000;
          }

          header .btn {
            position: fixed;
            padding: 0;
            z-index: 101;
          }
          header .btn img {
            filter: invert(1);
            width: 100%;
            height: auto;
          }
          header .prev {
            left: 0;
            width: 40px;
            top: 9px;
            color: #fff;
          }
          header .prev :global(svg) {
            transform: rotate(180deg);
            height: 20px;
            width: auto;
          }
          @media (min-width: 768px) {
            header .prev {
              top: 11px;
            }
          }
        `}
      </style>
    </header>
  );
};

export default HeaderDefault;
