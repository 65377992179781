import { head, truncate } from 'lodash';
import { format } from 'date-fns';
import {
  getNudgeHeaderParams,
  getNudgeItemTitle
} from '../advisorHub/utils/nudge';
import { HeaderProps } from '../components/HTMLHeader';
import { PotmContent } from '../components/PickOfTheMonth/mockData';
import {
  assetBaseUrl,
  chopardBrandId,
  getBrandNameById,
  inspifyBrandId,
  inspifyTestBrandId,
  louboutinBrandId,
  storyAssetBaseUrl
} from '../config';
import { i18n } from '../i18n';
import {
  IInspiration,
  INudge,
  IProduct,
  ISessionDetails,
  IStorybook,
  IVirtualBoutiqueConfig,
  ProductFamily
} from '../interfaces';
import { getNudgeItemImage } from './../advisorHub/utils/nudge';
import { isValidDate } from './clock';
import { getFileNameFromUrl } from './file';
import { getBrandAvatarUrl, getProductOGImageUrl } from './image';
import { currentLanguage, drawI18nt } from './language';
import { getProductDescription, getProductDisplayName } from './product';

export const defaultHeaderProps: HeaderProps = {
  title: 'INSPIFY',
  description: 'THE NEXT GEN VIRTUAL SALES PLATFORM',
  image: 'https://inspify.com/img/download.png'
};

const truncateText = (text) =>
  truncate(text, { length: 300, separator: '&#8230;' });

export const headerPropsForCompare = (brandId: string): HeaderProps => {
  const brandName = getBrandNameById(brandId);
  if (brandName) {
    return {
      title: `${brandName} | ${drawI18nt(i18n, 'compare_products')}`,
      description: `${brandName} | ${drawI18nt(i18n, 'compare_products')}`,
      image: getBrandAvatarUrl(brandId)
    };
  } else {
    return defaultHeaderProps;
  }
};

export const headerPropsForShowcase = (products: IProduct[]): HeaderProps => {
  if (!products || products.length === 0) return defaultHeaderProps;
  const product = products[0];
  const description =
    `${drawI18nt(i18n, 'showcase')?.toUpperCase()}: ` +
    truncateText(
      products
        .map((p) => getProductDisplayName(p, currentLanguage(i18n)))
        .join(', ')
    );
  const image = getBrandAvatarUrl(product.brandId);
  return {
    title: `${product.brandName} ${drawI18nt(i18n, 'showcase')}`,
    description,
    image
  };
};

export const headerPropsForProductStories = (
  product: IProduct
): HeaderProps => {
  if (!product) return defaultHeaderProps;
  return {
    title: `${drawI18nt(i18n, 'product_stories_from')} ${product.brandName}`,
    description: truncateText(
      `${drawI18nt(
        i18n,
        'product_stories'
      )?.toUpperCase()}: ${getProductDisplayName(
        product,
        currentLanguage(i18n)
      )}`
    ),
    image:
      product.imageUrls.length > 0
        ? product.imageUrls[0]
        : product.previewImageUrl
  };
};

export const headerPropsForProductInspirations = (
  product: IProduct
): HeaderProps => {
  if (!product) return defaultHeaderProps;
  return {
    title: `${drawI18nt(i18n, 'product_inspirations')?.toUpperCase()} | ${
      product.brandName
    }`,
    description: truncateText(
      `${drawI18nt(
        i18n,
        'product_inspirations'
      )?.toUpperCase()}: ${getProductDisplayName(
        product,
        currentLanguage(i18n)
      )}`
    ),
    image:
      product.imageUrls.length > 0
        ? product.imageUrls[0]
        : product.previewImageUrl
  };
};

export const headerPropsForInspiration = (
  inspiration: IInspiration
): HeaderProps => {
  if (!inspiration) return defaultHeaderProps;
  return {
    title: `${drawI18nt(i18n, 'inspiration')?.toUpperCase()} | ${
      inspiration.senderName
    }`,
    description: truncateText(
      `${inspiration.context ? inspiration.context + ': ' : ''}${
        inspiration.title
      }`
    ),
    image: inspiration.flipImageUrl
  };
};

export const headerPropsForProduct = (product: IProduct): HeaderProps => {
  if (!product) return defaultHeaderProps;
  return {
    title: getProductDisplayName(product, currentLanguage(i18n)),
    description: truncateText(
      getProductDescription(product, currentLanguage(i18n))
    ),
    image: getProductOGImageUrl(product)
  };
};

export const headerPropsForPickOfTheMonth = (
  product: IProduct,
  content: PotmContent
): HeaderProps => {
  if (!product) return defaultHeaderProps;
  const lang = currentLanguage(i18n);
  const desc = content.description[lang] || content.description['en'] || '';
  return {
    title: `${drawI18nt(
      i18n,
      'pick_of_the_month'
    ).toUpperCase()}: ${getProductDisplayName(product, currentLanguage(i18n))}`,
    description: desc
      .replace(/&quot;/g, '"')
      .replace(/<cite>/g, ' | ')
      .replace(/<[^>]*>/g, ''),
    image: getProductOGImageUrl(product)
  };
};

export const headerPropsForStorySearch = (brandId: string): HeaderProps => {
  const brandName = getBrandNameById(brandId);
  return {
    title: drawI18nt(i18n, 'story_search'),
    description: `${drawI18nt(i18n, 'story_search')} | ${brandName}`,
    image: getBrandAvatarUrl(brandId)
  };
};

export const headerPropsForProductSearch = (
  brandId: string,
  productFamilies: ProductFamily[]
): HeaderProps => {
  return {
    title: drawI18nt(i18n, 'product_search'),
    description: `${drawI18nt(i18n, 'product_search')}: ${productFamilies
      .map((f) => f.displayName.en)
      .join(', ')}`,
    image: getBrandAvatarUrl(brandId)
  };
};
const headerPropsForVBInSession = (session: ISessionDetails): HeaderProps => {
  const description = session?.description?.length
    ? `${session.description}`
    : `${
        session?.participants?.find(
          (participant) => participant?.role?.toLocaleLowerCase() === 'host'
        )?.name
      } is inviting you to a virtual meeting${
        isValidDate(new Date(`${session?.startDate}-${session?.startTime}`)) &&
        session?.sessionType === 'scheduled'
          ? `, Time: ${format(
              new Date(`${session.startDate}-${session.startTime}`),
              'MMM dd, yyyy - p'
            )} ${session.timezone}`
          : '.'
      }`;

  return {
    title: session?.topic,
    description: description,
    image: `${assetBaseUrl}/icons/joinmeetingv2.png`
  };
};
const headerPropsForVBNonMeeting = (
  config: IVirtualBoutiqueConfig
): HeaderProps => ({
  title: config.openGraphTitle || config.boutiqueName,
  description: config.description || 'You are invited to a meeting',
  image: config.openGraphImage,
  brandId: config.brandId
});

export const headerPropsForVirtualBoutique = (
  config: IVirtualBoutiqueConfig,
  session?: ISessionDetails
): HeaderProps => {
  const defaultProps = headerPropsForVBNonMeeting(config);

  if (
    session?.brand === inspifyBrandId ||
    session?.brand === inspifyTestBrandId ||
    config.isNotPreConfigured
  ) {
    const sessionProps = headerPropsForVBInSession(session);

    if (config.isNotPreConfigured) {
      sessionProps.title =
        session?.sessionType !== 'live'
          ? sessionProps.title || defaultProps.title
          : defaultProps.title;
      sessionProps.description =
        sessionProps.description || defaultProps.description;
      sessionProps.image = defaultProps.image || sessionProps.image;
    }
    return {
      ...defaultProps,
      ...sessionProps
    };
  }
  return defaultProps;
};
export const headerPropsForStoryJourney = (
  inspirations: IInspiration[]
): HeaderProps => {
  if (!inspirations || inspirations.length === 0) return defaultHeaderProps;
  const inspiration = head(inspirations);
  return {
    title: drawI18nt(i18n, 'story_journey'),
    description: `${drawI18nt(i18n, 'story_journey')}${
      inspiration ? ` | ${inspiration.senderName}` : ''
    }`,
    image: inspiration?.flipImageUrl,
    brandId: inspiration?.senderName
  };
};

export const headerPropsForNudgeMicroSite = (
  nudge: INudge,
  lang?: string,
  brandId?: string
): HeaderProps => {
  const language = lang || 'en';
  const param = getNudgeHeaderParams(brandId, language);

  if (!nudge.content?.length) {
    return {
      title: param.title,
      description: '',
      image: '',
      brandId
    };
  } else {
    const defaultDesc = param.description;
    const nudgeContentTitle0 =
      nudge.content[0] && getNudgeItemTitle(nudge.content[0], language);
    const nudgeContentTitle1 =
      nudge.content[1] && getNudgeItemTitle(nudge.content[1], language);
    const nudgeContentTitle2 =
      nudge.content[2] && getNudgeItemTitle(nudge.content[2], language);
    const itemsDesc =
      nudge.content[0] &&
      `${nudgeContentTitle0 ? nudgeContentTitle0 : ''}${
        nudgeContentTitle1 ? ', ' + nudgeContentTitle1 : ''
      }${nudgeContentTitle2 ? ', ' + nudgeContentTitle2 : ''}`;
    const description =
      brandId === chopardBrandId ? defaultDesc : `${defaultDesc}${itemsDesc}`;
    return {
      title: nudge?.ogTitle || param.title,
      description: nudge?.ogDescription || description,
      image: nudge?.ogImage || getNudgeItemImage(nudge.content[0]),
      brandId
    };
  }
};

export const headerPropsForSharedAsset = (
  assetPreviewURL: string,
  title: string,
  brandId: string
): HeaderProps => {
  return {
    title: title,
    image: assetPreviewURL,
    brandId,
    noCrawler: true
  };
};

export const headerPropsForStorybookMicroSite = (
  storybook: IStorybook,
  brandId: string
): HeaderProps => {
  const getAlternateImage = (url?: string) => {
    if (!url) return;
    const fileName = getFileNameFromUrl(url);
    return `${storyAssetBaseUrl}/content/converted/${
      storybook?.id
    }/featuredImages/${encodeURI(fileName)}`;
  };

  return {
    title: storybook.title || getBrandNameById(brandId),
    description: storybook.subtitle || '',
    image: storybook.featuredImage
      ? `${storyAssetBaseUrl}/${storybook.featuredImage}`
      : getAlternateImage(storybook.pages?.[0]?.thumbnail),
    brandId: storybook.brand || brandId,
    noCrawler: true
  };
};

export const headerPropsForLoubiAirwaysWishlist = (
  name: string,
  surname: string
): HeaderProps => {
  return {
    title: 'Loubi Airways',
    description: `${name ? name : ''} ${
      surname ? surname : ''
    } Favourites List`,
    image: `${assetBaseUrl}/loubiairways/pages/preview.jpg`,
    brandId: louboutinBrandId
  };
};
