import { format } from 'date-fns';
import { parseFromTimeZone } from 'date-fns-timezone';
import { orderBy } from 'lodash';
import { hasValidSession } from '../advisorHub/clientSideServices/login';
import { getCorrectHubHost } from '../advisorHub/utils/hubPagesRoute';
import { inspifyBrandId, inspifyTestBrandId, isProd } from '../config';
import {
  ISessionDetails,
  ISessionFormData,
  ISessionPayload,
  SessionStatus
} from '../interfaces';
import { mapSessionConvertedFilePayloadToFileList } from '../mappers/session';
import { mapStorybookToDocument } from '../mappers/storybook';
import { getCurrentHost } from './window';

export enum SessionFileStatus {
  READY = 'READY',
  PREPARE = 'PREPARE',
  ERROR = 'ERROR'
}

export const getSessionStatus = (session: ISessionDetails) => {
  const status = session?.status;
  if (!status) return;

  switch (status) {
    case 'SESSION_CREATED':
      return SessionStatus.SESSION_CREATED;
    case 'SESSION_PREPARED':
      return SessionStatus.SESSION_PREPARED;
    case 'SESSION_EDITED':
      return SessionStatus.SESSION_EDITED;
    case 'FAILED_TO_PREPARE':
      return SessionStatus.FAILED_TO_PREPARE;
  }
};

export const getSessionFileStatus = (
  session: ISessionDetails
): SessionFileStatus => {
  const uploadedFiles = session?.uploadedFiles?.length;
  const preparedFiles = session?.preparedFiles?.length;
  const sessionStatus = getSessionStatus(session);

  if (
    uploadedFiles &&
    uploadedFiles === preparedFiles &&
    sessionStatus === SessionStatus.SESSION_PREPARED
  )
    return SessionFileStatus.READY;
  if (
    (uploadedFiles !== preparedFiles &&
      sessionStatus === SessionStatus.SESSION_PREPARED) ||
    sessionStatus === SessionStatus.FAILED_TO_PREPARE
  )
    return SessionFileStatus.ERROR;

  if (
    (uploadedFiles && sessionStatus !== SessionStatus.SESSION_PREPARED) ||
    (uploadedFiles && sessionStatus === SessionStatus.SESSION_EDITED)
  )
    return SessionFileStatus.PREPARE;
  return undefined;
};

export const fileIsPrepared = (title?: string, session?: ISessionFormData) => {
  if (!title || !session) return;
  const preparedFiles = session?.preparedFiles;
  return preparedFiles?.find((file) => file?.title === title);
};

export const isSessionReady = (session: ISessionDetails) =>
  getSessionStatus(session) === SessionStatus.SESSION_PREPARED;

export const getSessionStartEndTime = (session: ISessionDetails): Date[] => {
  let startTime, endTime;
  try {
    startTime = parseFromTimeZone(
      `${session.startDate} ${session.startTime}`,
      'MM-DD-YYYY HH:mm',
      { timeZone: session.timezone }
    );
    endTime = parseFromTimeZone(
      `${session.endDate} ${session.endTime}`,
      'MM-DD-YYYY HH:mm',
      { timeZone: session.timezone }
    );
  } catch (error) {
    console.log('can not parse event time');
  }
  return [startTime, endTime];
};

export const getSessionUrlWithRegion = (
  url: string,
  region: string,
  regionInUrl?: string | string[]
): string | null => {
  if (
    (region && regionInUrl && region !== regionInUrl) ||
    (region && regionInUrl === '')
  ) {
    return url.replace(`region=${regionInUrl}`, `region=${region}`);
  }

  if (region && !regionInUrl) {
    return `${url}&region=${region}`;
  }

  return null;
};

export const getSessionDateString = (date: Date) => {
  try {
    return format(date, 'MM-dd-yyyy');
  } catch {
    return '';
  }
};

export const getSessionTimeString = (date: Date) => {
  try {
    return format(date, 'HH:mm');
  } catch {
    return '';
  }
};

export const getSessionDateFromString = (
  date: string,
  time: string
): Date | undefined => {
  try {
    const dateObj = new Date(`${date} ${time}`.replace(/-/g, '/'));
    if (isNaN(dateObj?.getTime())) {
      return;
    }
    return dateObj;
  } catch {
    return;
  }
};

export const getSessionAttachedFiles = (session: ISessionPayload) => {
  const sortedFiles = orderBy(session.preparedFiles, ['order'], ['asc']);
  return sortedFiles.map((file, index) =>
    mapSessionConvertedFilePayloadToFileList(undefined)(file, index)
  );
};

export const getSessionStorybookFiles = (session: ISessionPayload) =>
  session.preparedStoryBook?.map((storybook) =>
    mapStorybookToDocument(storybook)
  ) || [];

export const getHostAuthRedirect = (
  role: string,
  brandId: string
): Promise<string | null> => {
  const brandsToCheckHostAuthInSession = [inspifyBrandId, inspifyTestBrandId];
  if (!brandsToCheckHostAuthInSession.includes(brandId)) {
    return Promise.resolve(null);
  }
  if (['advisor', 'mc'].includes(role?.toLowerCase())) {
    return hasValidSession().then((valid) => {
      if (valid) {
        return null;
      }
      return isProd
        ? `https://${getCorrectHubHost(brandId)}/hub`
        : `${getCurrentHost()}/hub/${brandId}`;
    });
  }
  return Promise.resolve(null);
};
