import { assetBaseUrl, videoBaseUrl } from '../config';
import {
  IFile,
  ISessionConvertedFile,
  ISessionFormData,
  ISessionPayload
} from '../interfaces';
import { removeFileExtension, isPDF, getFileNameFromUrl } from '../utils/file';
import { toS3Url } from '../utils/image';
import {
  getSessionDateFromString,
  getSessionDateString,
  getSessionTimeString
} from '../utils/session';

interface ConvertedItem {
  bucket: string;
  key: string;
}

const mapUrl = (
  bucket: string,
  key: string,
  urlSigner?: (url: string) => string
) => {
  if (bucket === 'inspify-videos') {
    return `${videoBaseUrl}/${key}`;
  }
  if (bucket === 'inspify-assets') {
    return `${assetBaseUrl}/${key}`;
  }
  if (typeof urlSigner === 'function') {
    return urlSigner(`${bucket}/${key}`);
  }

  return toS3Url(`${bucket}/${key}`);
};

const getPagesInConvertedItems = (
  convertedItems: ConvertedItem[]
): ConvertedItem[] =>
  convertedItems.filter(
    (item) =>
      !getFileNameFromUrl(item.key).includes('_thumbnail.') && !isPDF(item.key)
  );

const getThumbnailOfPagesInConvertedItems = (
  pages: ConvertedItem[],
  allItems: ConvertedItem[]
): ConvertedItem[] => {
  let thumbnails = [];
  pages.forEach((page) => {
    const pageKeyNoExtension = page.key.replace(/\.[^.$]+$/, '');
    const thumbnail =
      allItems.find(
        (item) =>
          item.bucket === page.bucket &&
          item.key.includes(pageKeyNoExtension + '_thumbnail')
      ) || page;
    thumbnails = [...thumbnails, thumbnail];
  });
  return thumbnails;
};

export const mapSessionConvertedFilePayloadToFileList =
  (urlSigner?: (url: string) => string) =>
  (payload: ISessionConvertedFile, identifier?: number | string): IFile => {
    const { title, convertedItems } = payload;

    const cleanTitle = removeFileExtension(title);

    if (!convertedItems?.length) {
      return {
        id: `${identifier !== undefined ? `${identifier} - ` : ''}` + title,
        title: cleanTitle,
        pages: [],
        thumbnails: []
      };
    }
    const filteredPreview = convertedItems?.filter(
      (item) => !item.key.includes('_preview.')
    );

    const pagesInConvertedItems = getPagesInConvertedItems(filteredPreview);
    const thumbnailsInConvertedItems = getThumbnailOfPagesInConvertedItems(
      pagesInConvertedItems,
      filteredPreview
    );

    return {
      id: `${identifier !== undefined ? `${identifier} - ` : ''}` + title,
      title: cleanTitle,
      thumbnails: thumbnailsInConvertedItems.map((item) =>
        mapUrl(item.bucket, item.key, urlSigner)
      ),
      pages: pagesInConvertedItems.map((item) =>
        mapUrl(item.bucket, item.key, urlSigner)
      )
    };
  };

export const combineFilesIntoSingleDocument = (
  files: IFile[],
  title?: string
): IFile | undefined => {
  if (!files.length) return;

  let pages = [],
    thumbnails = [];

  files.forEach((file) => {
    pages = [...pages, ...file.pages];
    thumbnails = [...thumbnails, ...file.thumbnails];
  });

  return {
    id: title,
    title: removeFileExtension(title || files[0].title),
    pages,
    thumbnails
  };
};

export const mapSessionFormDataToPayload = (
  data: ISessionFormData,
  createdBy: string,
  storeId: string,
  brandId: string,
  isEdit: boolean,
  isMeetingRoom: boolean
): ISessionPayload => {
  const {
    id,
    topic,
    type,
    description,
    recordSession,
    hostMuteVideo,
    participantMuteVideo,
    joinAnytime,
    muteUponEntry,
    eventCountdownPage,
    uploadedFiles,
    preparedFiles,
    uploadedStoryBook,
    preparedStoryBook,
    status,
    region,
    modifiedAt,
    createdAt,
    modifiedBy,
    timezone,
    startDate,
    endDate,
    startTime,
    endTime,
    participants,
    storybooks
  } = data;
  return {
    id,
    storeId,
    topic,
    sessionType: isMeetingRoom ? 'room' : type || 'scheduled',
    description,
    recordSession: recordSession ? 1 : 0,
    hostMuteVideo: hostMuteVideo ? 1 : 0,
    participantMuteVideo: participantMuteVideo ? 1 : 0,
    joinAnytime: joinAnytime ? 1 : 0,
    muteUponEntry: muteUponEntry ? 1 : 0,
    eventCountdownPage: eventCountdownPage ? 1 : 0,
    uploadedFiles: uploadedFiles || [],
    preparedFiles: preparedFiles || [],
    uploadedStoryBook,
    preparedStoryBook,
    createdBy,
    status: isEdit ? 'SESSION_EDITED' : status || '',
    region,
    modifiedAt: modifiedAt || '',
    createdAt: createdAt || '',
    brand: brandId,
    modifiedBy: modifiedBy || '',
    timezone,
    startDate: getSessionDateString(startDate),
    endDate: getSessionDateString(endDate),
    startTime: getSessionTimeString(startTime),
    endTime: getSessionTimeString(endTime),
    participants,
    storybooks
  };
};

export const mapSessionPayloadToFormData = (
  session: ISessionPayload
): ISessionFormData => {
  const startDate = getSessionDateFromString(
    session?.startDate,
    session?.startTime
  );
  const endDate = getSessionDateFromString(session?.endDate, session?.endTime);
  return {
    ...session,
    startTime: startDate,
    startDate,
    endTime: endDate,
    endDate
  };
};
